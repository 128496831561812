<template>
  <div class="flix-form-group flix-list-group">
    <h4 class="flix-html-h4">{{ list.length }} {{ $tc('message.user', list.length) }} <span v-if="list.length !== 1">haben</span><span v-else>hat</span> Zugriff auf diese Organisation</h4>
    <div v-for="(li, index) in list" :key="li.ID" class="flix-list-group-item">
      <b>{{ li.username }}</b>
      <span style="margin: 0 10px">
        <span v-if="$store.state.user.user.username === li.username" style="display: inline-block" class="flix-badge"> {{  $t('message.' + li.role) }}</span>
        <a v-else style="display: inline-block; text-decoration: none;" href="#" @click.prevent="function () { editRole(li) }" class="flix-badge"><flixIcon id="wrench" /> {{  $t('message.' + li.role) }}</a>
      </span>
      <a href="#" class="flix-html-a" style="margin-left: 5px" @click.prevent="function () { changeStatus(index) }">
        <status :status="li.status" /> Zugriff {{ $t('message.' + li.status) }}
      </a>
      <div class="flix-pull-right" v-if="checkAdminCount(index)">
        <delete style="margin-left: 10px" :callback="function () { setDelete(index) }"></delete>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    status () { return import('@/components/permissions/organisations/list/status.vue') },
    delete () { return import('./delete') }
  },
  props: {
    editRole: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      list: false,
      admins: 0
    }
  },
  computed: {

  },
  mounted () {
    this.getMembers()
  },
  methods: {
    changeStatus (index) {
      if (this.list[index].status === 'active') {
        this.list[index].status = 'inactive'
      } else {
        this.list[index].status = 'active'
      }
      this.$flix_post({
        url: 'organisation/member/status',
        data: {
          user: this.$store.getters.user.md5_id,
          member: this.list[index],
          organisation: this.$route.params.action
        },
        callback: function (ret) {
          if (!ret.data[0]) {
            if (this.list[index].status === 'active') {
              this.list[index].status = 'inactive'
            } else {
              this.list[index].status = 'active'
            }
          }
        }.bind(this)
      })
    },
    setDelete (index) {
      this.$flix_post({
        url: 'organisation/member/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          organisation: this.$route.params.action,
          member: this.list[index].user
        },
        callback: function (ret) { this.list.splice(index, 1); this.getAdminCount() }.bind(this)
      })
    },
    setSave (ret) {
      this.list = ret.data[1]
      this.getAdminCount()
    },
    getMembers () {
      this.$flix_post({
        url: 'organisation/member/get',
        data: {
          user: this.$store.getters.user.md5_id,
          organisation: this.$route.params.action
        },
        callback: this.setSave
      })
    },
    getAdminCount () {
      const admins = this.list.filter(function (li) {
        if (li.role === 'admin') {
          return true
        }
        return false
      })
      this.admins = admins.length
    },
    checkAdminCount (index) {
      if (this.list[index].role === 'admin' && this.admins <= 1) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
